/* global Component */
class homeItemSectionComponent extends Component {

    static name() {
        return "homeItemSectionComponent";
    }

    static componentName() {
        return "homeItemSectionComponent";
    }

    getProps() {
        return ["section"];
    }

    data() {
        return {
            items:[],
        };
    }

    mounted() {
        return function () {
            this.forceUpdate();
        };
    }

    getWatch() {
        return {
            '$route': 'forceUpdate',
            '$store.state.itemTimeFlag':'forceUpdate',
            '$store.state.itemUpdate': 'forceUpdate'
        };
    }

    getMethods() {
        return {
            forceUpdate:this.forceUpdate
        };
    }

    getComputed(){
        return {
            itemsWithPirceNotZero:function (){
                let self = this;
                let itemFiltered = [];
                for(let item of this.items){
                    let storeItem = self.$store.getters.getItemByCode(item.fields.ItemCode);
                    if(storeItem && self.$store.getters.showItemPrice(storeItem) > 0)
                        itemFiltered.push(item);
                }
                //itemFiltered = this.items.filter(item=>self.$store.getters.showItemPrice(self.$store.getters.getItemByCode(item.fields.ItemCode)) > 0);
                //console.log(this.section.Title,itemFiltered);
                return itemFiltered;
            }
        };
    }

    forceUpdate() {
        this.items = [];
        let itemsSelected = [];
        //console.log( "Home Item Section 1 ",this.$store.getters.getHomeItems );
        for(let item of this.$store.getters.getHomeItems) {
            if(item.fields.PageSectionSection == this.section.Code)
                switch (this.section.SectionType) {
                    case 'htmlSection':
                        itemsSelected.push(item);
                        break;
                    case 'itemCard': {
                        let store_item = this.$store.getters.getItemByCode(item.fields.ItemCode);
                        if (store_item) {
                            itemsSelected.push(store_item);
                        } else {
                            // eslint-disable-next-line no-console
                            console.log("ITem not found", this.section, item.fields.ItemCode, item, store_item);
                        }
                        break;
                    }
                    case 'itemList':
                    case 'slidersmall':
                    case 'categoryList':
                    case 'officesList':
                    case 'imageColumns':
                    case 'slider':
                        if (item.fields.PageSectionSection === this.section.Code)
                            itemsSelected.push(item);
                        break;
                    default:
                        // eslint-disable-next-line no-console
                        console.log('Section with out type', this.section);
                }
        }
        this.items = itemsSelected;
    }

    getTemplate() {
        return `<div :id="'section-'+section.Code.replace(' ','-')" :key="'section-home-'+section.Code" class="row mx-0" if="isReady">
                  <template v-if="section.SectionType=='itemList'" >
                    <template v-if="$store.getters.getSetting.ShowZeroPrice==false">
                        <h5 class="col-sm-12 section-title" v-if="!section.HideTitle">{{section.Title}} <small>({{items.length}})</small></h5>  
                        <div class="row col justify-content-center">
                          <template v-for="item of items">
                                <homeItemComponent :itemCode="item.fields.ItemCode"></homeItemComponent>
                          </template>
                        </div> 
                    </template>
                    <template v-else>
                        <template v-if="itemsWithPirceNotZero.length > 0">
                              <h5 class="col-sm-12 section-title" v-if="!section.HideTitle">{{section.Title}} <small>({{itemsWithPirceNotZero.length}})</small></h5>
                              <div class="row col justify-content-center">
                                  <template v-for="item of itemsWithPirceNotZero">
                                    <homeItemComponent :itemCode="item.fields.ItemCode"></homeItemComponent>
                                  </template>
                              </div>
                        </template>
                    </template>
                  </template>
                  <template v-else-if="section.SectionType=='slider'">
                      <sliderSectionComponent :items="items" :config="section"/>
                  </template>
                  <template v-if="section.SectionType=='itemCard'">
                     <homeItemCardComponent :items="items" :title="section.Title || ''" :hideTitle="section.HideTitle==1" :section="section"/>
                  </template>
                  <template v-if="section.SectionType=='categoryList'">
                     <imageCollectionSectionComponent :section="section" :items="items"></imageCollectionSectionComponent>
                  </template>
                  <template v-if="section.SectionType=='officesList'">
                     <imageCollectionSectionComponent :section="section" :items="items"></imageCollectionSectionComponent>
                  </template>
                  <template v-else-if="section.SectionType=='slidersmall'">
                      <sliderSmallSectionComponent :items="items" :config="section"/>
                  </template>
                  <template v-else-if="section.SectionType=='imageColumns'">
                      <columnsSectionComponent :items="items" :config="section"/>
                  </template>
                  <template v-else-if="section.SectionType=='htmlSection'">
                      <htmlSectionComponent :items="items" :config="section"></htmlSectionComponent>
                  </template>
              </div>`;
    }
}

homeItemSectionComponent.registerComponent();